import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAuth } from "../contexts/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import config from "../config";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../queries/Users";
import { useAgents } from "../context/AgentContext";

function Header() {
  const { user: authUser, logout } = useAuth();
  const { loginWithRedirect } = useAuth0();
  const { data } = useQuery(GET_ME, {
    skip: !authUser
  });
  const { getUserColor } = useAgents();

  const getInitials = (firstname?: string, lastname?: string) => {
    return `${firstname?.[0] || '?'}${lastname?.[0] || '?'}`.toUpperCase();
  };

  const user = data?.me;
  const userColor = user?.id ? getUserColor(user.id) : '#5D8CAE';

  return (
    <Navbar
      expand="lg"
      className="py-3 border-bottom border-light-subtle fixed-top bg-white"
    >
      <Container fluid={true}>
        <Link className="navbar-brand" to="/">
          <img alt={"logo"} src={'/images/logo-inline.png'} style={{maxHeight: '40px'}}/>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" role="a">
          <Nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            {!authUser && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as="button"
                    onClick={() =>
                      loginWithRedirect({
                        appState: {
                          redirect_uri: config.AUTH_REDIRECT_URI,
                        },
                      })
                    }
                  >
                    Login
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {authUser && (
              <>
                <Nav.Item className="d-flex align-items-center me-2">
                  <span className="text-muted">{data?.me?.email}</span>
                </Nav.Item>
                <NavDropdown
                  id="nav-dropdown"
                  className="dropdown-no-arrow"
                  align="end"
                  title={
                    <div 
                      className="d-flex align-items-center justify-content-center rounded-circle text-white"
                      style={{ 
                        width: '32px', 
                        height: '32px', 
                        fontSize: '14px',
                        backgroundColor: userColor
                      }}
                    >
                      {getInitials(user?.firstName, user?.lastName)}
                    </div>
                  }
                >
                  <NavDropdown.Item href="" disabled={true}>
                    My Account
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as="button" onClick={logout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
