import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAgents, User } from '../../context/AgentContext';
import './styles.css';

interface UserAvatarProps {
  // Allow passing either a user ID or a complete user object
  userId?: string;
  user?: User;
  // Optional override props
  size?: number;
  showTooltip?: boolean;
  className?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  userId,
  user: userProp,
  size = 36,
  showTooltip = true,
  className = '',
}) => {
  const { getUserColor, users } = useAgents();
  
  // Get user from context if only userId is provided
  const user = userProp || (userId ? users.find(u => u.id === userId) : undefined);
  
  // Get user initials from first and last name
  const getInitials = (): string => {
    if (!user?.firstName && !user?.lastName) return '??';
    return `${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`.toUpperCase();
  };
  
  // Get background color based on userId
  const backgroundColor = user?.id ? getUserColor(user.id) : (userId ? getUserColor(userId) : 'var(--bs-primary)');
  
  // Full name or email for tooltip
  const tooltipText = user ? 
    (`${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email || 'Anonymous') : 
    'Unknown User';
  
  // Avatar element with styles
  const avatarElement = (
    <div
      className={`user-avatar d-flex align-items-center justify-content-center rounded-circle text-white ${className}`}
      style={{
        width: size,
        height: size,
        fontSize: Math.floor(size * 0.4),
        backgroundColor,
        borderColor: backgroundColor
      }}
    >
      {getInitials()}
    </div>
  );

  // Return with tooltip if enabled, otherwise just the avatar
  if (showTooltip) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{tooltipText}</Tooltip>}
      >
        {avatarElement}
      </OverlayTrigger>
    );
  }
  
  return avatarElement;
};

export default UserAvatar; 