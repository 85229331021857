import React, { useEffect } from 'react';
import { Comment } from '../types/transcript';
import { GET_ME } from "../../../queries/Users";
import { Dropdown } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import { useAutoExpandingTextarea } from '../../../hooks/useAutoExpandingTextarea';
import { formatTimeToMinSec } from '../../../utils/formatters';
import UserAvatar from '../../../components/UserAvatar';

export interface SaveCommentProps {
    id: string;
    quotedText: string;
    startWordIndex: number;
    endWordIndex: number;
    text: string;
    createdDateTime: string;
}

interface CommentSidePanelProps {
    selectedComment: Comment | null;
    comments: Comment[];
    onClose: () => void;
    onDeleteComment: (commentId: string) => void;
    onSaveComment: (comment: SaveCommentProps) => void;
    onSaveReply: (parentCommentId: string, text: string) => void;
    words?: Array<{ start: number; text: string; }>;
    onSeekTime?: (time: number) => void;
    onPlaySegment?: (start: number, end: number) => void;
}

export const CommentSidePanel = React.memo<CommentSidePanelProps>(({
    selectedComment,
    comments,
    onClose,
    onDeleteComment,
    onSaveComment,
    onSaveReply,
    words = [],
    onSeekTime,
    onPlaySegment
}) => {
    const [replyText, setReplyText] = React.useState('');
    const [newCommentText, setNewCommentText] = React.useState('');
    const { data: meData } = useQuery(GET_ME);
    const replyTextareaRef = React.useRef<HTMLTextAreaElement>(null);
    useAutoExpandingTextarea(replyTextareaRef);

    useEffect(() => {
        console.log('Selected comment changed:', selectedComment);
    }, [selectedComment]);

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const getStartTimeForWordIndex = (wordIndex: number): number => {
        console.log('words', words[wordIndex], wordIndex);
        if (!words || !Array.isArray(words) || wordIndex >= words.length || wordIndex < 0) {
            return 0;
        }
        const word = words[wordIndex];
        return word?.start ?? 0;
    };

    const getEndTimeForWordIndex = (wordIndex: number): number => {
        if (!words || !Array.isArray(words) || wordIndex >= words.length || wordIndex < 0) {
            return 0;
        }
        if (wordIndex + 1 < words.length) {
            return words[wordIndex + 1].start;
        }
        return words[wordIndex].start + 2;
    };

    const renderTimestamp = (wordIndex: number, endWordIndex: number) => {
        const time = getStartTimeForWordIndex(wordIndex);
        const endTime = getEndTimeForWordIndex(endWordIndex);
        return (
            <div 
                className="text-muted d-flex align-items-center gap-1" 
                style={{ 
                    fontSize: 12,
                    cursor: 'pointer'
                }}
                onClick={() => {
                    if (onPlaySegment) {
                        console.log('Playing segment', time, endTime);
                        onPlaySegment(time, endTime);
                    } else {
                        console.log('Seeking to', time);
                        onSeekTime?.(time);
                    }
                }}
                title="Click to play this segment"
            >
                {formatTimeToMinSec(time)}
                <i className="bi bi-play-fill"></i>
            </div>
        );
    };

    const renderComment = (comment: Comment) => (
        <div className="mb-4">
            {/* Main comment */}
            <div>
                {/* Quoted text with timestamp */}
                {comment.quotedText && (
                    <>
                        {renderTimestamp(comment.quotedTextStartWord, comment.quotedTextEndWord)}
                        <div className="border-start ps-2 mb-4" style={{ borderLeftWidth: "3px" }}>
                            <span style={{ fontSize: 14 }} className="d-block text-muted">
                                {comment.quotedText}
                            </span>
                        </div>
                    </>
                )}
                {/* User info and comment */}
                <div className="d-flex align-items-start gap-2">
                    <UserAvatar 
                        userId={comment.user?.id}
                    />
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <small className="text-muted text-xs">
                                {formatDate(comment.createdDateTime)}
                            </small>
                            {comment.user?.id === meData?.me?.id && (
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" size="sm" className="p-0">
                                        ⋮
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => onDeleteComment(comment.id)}>
                                            Delete comment
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                        <div>{comment.text}</div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderNewComment = (comment: Comment) => (
        <div className="mb-4">
            <div>
                {/* Quoted text with timestamp */}
                <div className="border-start ps-2 mb-4" style={{ borderLeftWidth: "3px" }}>
                    {renderTimestamp(comment.quotedTextStartWord, comment.quotedTextEndWord)}
                    <span style={{ fontSize: 14 }} className="d-block text-muted">
                        {comment.quotedText}
                    </span>
                </div>
                {/* Input area */}
                <div className="d-flex align-items-start gap-2">
                    <UserAvatar 
                        userId={meData?.me?.id}
                    />
                    <div className="flex-grow-1">
                        <textarea
                            className="form-control mb-2"
                            placeholder="Add your comment..."
                            value={newCommentText}
                            onChange={(e) => setNewCommentText(e.target.value)}
                            rows={3}
                        />
                        <div className="d-flex justify-content-end gap-2">
                            <button
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    onDeleteComment(comment.id);
                                    setNewCommentText('');
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                disabled={!newCommentText.trim()}
                                onClick={() => {
                                    onSaveComment({
                                        id: comment.id,
                                        quotedText: comment.quotedText,
                                        startWordIndex: comment.startWordIndex,
                                        endWordIndex: comment.endWordIndex,
                                        text: newCommentText,
                                        createdDateTime: new Date().toISOString()
                                    });
                                    setNewCommentText('');
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="h-full flex flex-col">
            {/* Header */}
            <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <h5 className="mb-0">
                    {selectedComment ? 
                        (selectedComment.id.startsWith('temp-') ? 'New Comment' : 'Comment Thread') 
                        : 'New Comment'}
                </h5>
                <button
                    className="btn btn-link p-0 text-muted"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <i className="bi bi-x-lg"></i>
                </button>
            </div>

            {/* Content */}
            <div className="flex-1 overflow-auto p-3">
                {selectedComment && (
                    <>
                        {selectedComment.id.startsWith('temp-') 
                            ? renderNewComment(selectedComment)
                            : (
                                <>
                                    {renderComment(selectedComment)}
                                    
                                    {/* Replies */}
                                    {comments
                                        .filter(reply => reply.parentCommentId === selectedComment.id)
                                        .sort((a, b) => new Date(a.createdDateTime).getTime() - new Date(b.createdDateTime).getTime())
                                        .map(reply => (
                                            <div key={reply.id} className="mt-2">
                                                {renderComment(reply)}
                                            </div>
                                        ))}

                                    <div className="mt-3 pt-3">
                                        <div className="d-flex align-items-start">
                                            <UserAvatar 
                                                userId={meData?.me?.id}
                                                className="me-2"
                                            />
                                            <div className="flex-grow-1">
                                                <textarea
                                                    ref={replyTextareaRef}
                                                    className="form-control"
                                                    placeholder="Reply to comment..."
                                                    value={replyText}
                                                    onChange={(e) => setReplyText(e.target.value)}
                                                    rows={1}
                                                    style={{
                                                        minHeight: '31px',
                                                        resize: 'none',
                                                        overflow: 'hidden',
                                                        wordBreak: 'break-word',
                                                        whiteSpace: 'pre-wrap'
                                                    }}
                                                />
                                            </div>
                                            <button
                                                className="btn btn-primary btn-sm ms-2"
                                                type="button"
                                                onClick={() => {
                                                    onSaveReply(selectedComment.id, replyText);
                                                    setReplyText('');
                                                }}
                                                disabled={!replyText.trim()}
                                                style={{ height: '36px' }}
                                            >
                                                Reply
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                    </>
                )}
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.selectedComment?.id === nextProps.selectedComment?.id &&
        prevProps.comments.length === nextProps.comments.length &&
        prevProps.words === nextProps.words
    );
});