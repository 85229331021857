import React, { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import './styles.css';
import { useAgents, User } from '../../context/AgentContext';
import UserAvatar from '../UserAvatar';

export interface UserFollow {
  createdByUserId: string;
}

interface BookmarkCirclesProps {
  follows: UserFollow[];
  currentUserId?: string;
  maxVisible?: number;
}

const BookmarkCircles: React.FC<BookmarkCirclesProps> = ({
  follows,
  currentUserId,
  maxVisible = 6
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);
  const { users } = useAgents();

  // Find user details from AgentContext
  const getUserDetails = (userId: string): User | undefined => {
    return users.find(user => user.id === userId);
  };

  // Sort follows with current user first, then alphabetically
  const sortedFollows = [...follows].sort((a, b) => {
    // Current user always comes first
    if (a.createdByUserId === currentUserId) return -1;
    if (b.createdByUserId === currentUserId) return 1;
    
    // Get complete user details from context
    const userA = getUserDetails(a.createdByUserId);
    const userB = getUserDetails(b.createdByUserId);
    
    // Then sort alphabetically by name
    const nameA = `${userA?.firstName || ''} ${userA?.lastName || ''}`.trim();
    const nameB = `${userB?.firstName || ''} ${userB?.lastName || ''}`.trim();
    
    return nameA.localeCompare(nameB);
  });

  const visibleFollows = sortedFollows.slice(0, maxVisible);
  const hasMore = follows.length > maxVisible;

  return (
    <div className="bookmark-circles">
      <div 
        ref={target}
        className="circle-container" 
        onClick={() => setShowPopover(!showPopover)}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        aria-label={`${follows.length} bookmarks`}
        tabIndex={0}
      >
        {visibleFollows.map((follow, index) => {
          const user = getUserDetails(follow.createdByUserId);
          return (
            <div 
              key={follow.createdByUserId}
              style={{ zIndex: visibleFollows.length - index }}
            >
              <UserAvatar
                userId={user?.id}
                size={32}
                showTooltip={false}
              />
            </div>
          );
        })}
        
        {hasMore && (
          <div 
            className="initial-circle more-circle"
            title={`${follows.length - maxVisible} more users`}
          >
            ...
          </div>
        )}
      </div>
      
      <Overlay
        show={showPopover}
        target={target.current}
        placement="right"
        container={document.body}
        rootClose
        onHide={() => setShowPopover(false)}
      >
        <Popover id="bookmark-popover">
          <Popover.Body>
            <div className="popover-circles">
              {sortedFollows.map((follow) => {
                const user = getUserDetails(follow.createdByUserId);
                return (
                  <div key={follow.createdByUserId} className="popover-user">
                    <UserAvatar
                      userId={user?.id}
                      size={32}
                      showTooltip={false}
                    />
                    <span className="user-name">
                      {`${user?.firstName || ''} ${user?.lastName || ''}`.trim() || user?.email}
                      {follow.createdByUserId === currentUserId && ' (You)'}
                    </span>
                  </div>
                );
              })}
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default BookmarkCircles; 