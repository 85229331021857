import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTime from "../../components/DateTime";
import CallContent from "./components/CallContent";
import { NavLink, useParams } from "react-router-dom";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { AudioPlayer } from "../../components/AudioPlayer";
import {   
  CREATE_PHONECALL_BOOKMARK,
  CREATE_PHONECALL_COMMENT,
  CREATE_PHONECALL_COMMENT_REPLY,
  DELETE_PHONECALL_BOOKMARK,
  DELETE_PHONECALL_COMMENT,
  UPDATE_PHONECALL_REVIEW_STATUS,
  PHONECALL_CHECKLIST_CREATE,
  PHONECALL_CHECKLIST_ITEM_UPDATE
} from "./gql/mutations";
import { GET_CALL, GET_PHONECALL_REVIEW_STATUSES, GET_CHECKLIST_TEMPLATES } from "./gql/queries";
import {
  ADD_TAG_TO_CALL,
  REMOVE_TAG_FROM_CALL, 
  GET_TAGS,
  REVIEW_TAG_FROM_CALL,
} from "../../queries/Calls";
import { Form } from "react-bootstrap";
import { PHONECALL_UPDATES } from "../../subscriptions/phonecall";
import { sendAmplitudeData } from "../../services/amplitudeClient";
import { useState, useEffect, useCallback } from "react";
import { Tag } from "../../components/Tag";
import { AppliedTag } from "../../components/AppliedTag";
import { CommentSidePanel, SaveCommentProps } from "./components/CommentSidePanel";
import { Comment } from "./types/transcript";
import { toast } from "react-toastify";
import PhoneDisplay from "../../components/PhoneDisplay";
import { ChecklistSidePanel } from "./components/ChecklistSidePanel";
import { ChecklistModal } from "./components/ChecklistModal";

// Move sidebarStyles definition here (unchanged)
const sidebarStyles = {
  width: '400px',
  height: 'calc(100vh - 64px)',
  position: 'fixed' as const,
  right: 0,
  top: '68px',
  backgroundColor: '#f8f9fa',
  borderLeft: '1px solid #dee2e6',
  padding: '1rem',
  overflowY: 'auto' as const,
  marginTop: '1rem',
};

export const Call = () => {
  const params = useParams();
  const id = params.id;
  const { loading, data, refetch } = useQuery(GET_CALL, {
    variables: { id },
  });

  //const [deletePhonecallFunction] = useMutation(DELETE_PHONECALL);
  //const [createTranscriptionFunction] = useMutation(CREATE_TRANSCRIPTION);
  const [createBookmarkFunction] = useMutation(CREATE_PHONECALL_BOOKMARK);
  const [deleteBookmarkFunction] = useMutation(DELETE_PHONECALL_BOOKMARK);
  const isCheckboxChecked = data?.phonecall.follows.length > 0;
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [seekTo, setSeekTo] = useState<((time: number) => void) | null>(null);
  const [newTag, setNewTag] = useState("");
  const [isAddingTag, setIsAddingTag] = useState(false);

  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [showCommentBox, setShowCommentBox] = useState(false);

  // Update checklist state to match server data structure
  const [activeChecklistId, setActiveChecklistId] = useState<string | null>(null);
  const [showChecklistPanel, setShowChecklistPanel] = useState(false);
  
  // Add queries and mutations for checklists
  const { data: checklistTemplatesData, loading: loadingTemplates } = useQuery(GET_CHECKLIST_TEMPLATES);
  const [createChecklist, { loading: creatingChecklist }] = useMutation(PHONECALL_CHECKLIST_CREATE);
  const [updateChecklistItem] = useMutation(PHONECALL_CHECKLIST_ITEM_UPDATE);

  // Add mutations for tags
  const [addTagFunction] = useMutation(ADD_TAG_TO_CALL);
  const [removeTagFunction] = useMutation(REMOVE_TAG_FROM_CALL);
  const [reviewTagFunction] = useMutation(REVIEW_TAG_FROM_CALL);

  // Add query for available tags
  const { data: tagsData } = useQuery(GET_TAGS);
  const [showTagSuggestions, setShowTagSuggestions] = useState(false);

  // Filter tags based on input
  const filteredTags = tagsData?.tags.filter(tag => 
    tag.name.toLowerCase().includes(newTag.toLowerCase()) &&
    !data?.phonecall.tags?.some(existingTag => existingTag.id === tag.id)
  ) || [];

  const [comments, setComments] = useState<Comment[]>([]);
  
  // Update comments when data changes
  useEffect(() => {
    if (data?.phonecall.comments) {
      setComments(data.phonecall.comments);
    }
  }, [data?.phonecall.comments]);

  useSubscription(PHONECALL_UPDATES);

//  const handleDelete = async (phonecallId) => {
//    sendAmplitudeData("phonecall-delete", { id: phonecallId });
//    await deletePhonecallFunction({
//      variables: {
//        phonecallId,
//      },
//    });
//    navigate(`/dashboard`);
//  };
//
//  const handleCreateTranscription = async (phonecallId) => {
//    sendAmplitudeData("phonecall-transcribe", { id: phonecallId });
//    await createTranscriptionFunction({
//      variables: {
//        id: phonecallId,
//      },
//    });
//  };

  const handleSetBookmarked = async (phonecallId: string, checked: boolean) => {
    if (!checked) {
      sendAmplitudeData("phonecall-bookmark-delete", { id: phonecallId });
      await deleteBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id,
            __typename: "UserPhonecallFollow",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
      await refetch();
    } else {
      sendAmplitudeData("phonecall-bookmark-create", { id: phonecallId });
      await createBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
      });
      await refetch();
    }
    //setBookmarked(checked);
  };

  const handleMouseUpOnTranscription = () => {
    console.log(`Selected text: ${window.getSelection().toString()}`);
  };

  const handleAddTag = async (tagId?: string, tagName?: string) => {
    try {
      setIsAddingTag(true);
      sendAmplitudeData("phonecall-tag-add", { id: id });
      await addTagFunction({
        variables: {
          phonecallId: id,
          name: tagName || newTag.trim(),
          ...(tagId && { tagId })
        },
      });
      setNewTag("");
      setShowTagSuggestions(false);
      await refetch();
    } catch (error) {
      console.error("Error adding tag:", error);
    } finally {
      setIsAddingTag(false);
    }
  };

  const handleRemoveTag = async (tagId: string) => {
    try {
      sendAmplitudeData("phonecall-tag-remove", { id: id });
      await removeTagFunction({
        variables: {
          phonecallId: id,
          tagId: tagId
        },
      });
      await refetch();
    } catch (error) {
      console.error("Error removing tag:", error);
    }
  };

  const handleReviewTag = async (tagId: string, approved: boolean) => {
    try {
      await reviewTagFunction({ variables: { phonecallId: id, tagId, approved } });
      await refetch();
    } catch (error) {
      console.error("Error reviewing tag:", error);
    }
  };

  const [createComment] = useMutation(CREATE_PHONECALL_COMMENT);
  const [createReply] = useMutation(CREATE_PHONECALL_COMMENT_REPLY);
  const [deleteComment] = useMutation(DELETE_PHONECALL_COMMENT);  

  const handleDeleteComment = async (commentId: string) => {
    // For temporary comments, just remove from local state
    if (commentId.startsWith('temp-')) {
      setComments(prevComments => prevComments.filter(c => c.id !== commentId));
      setSelectedComment(null);
      return;
    }

    // For saved comments, delete from server
    try {
      const commentToDelete = comments.find(c => c.id === commentId);
      await deleteComment({ variables: { id: commentId } });
      
      // Only clear selected comment if it's a top-level comment (no parent)
      if (!commentToDelete?.parentCommentId) {
        setSelectedComment(null);
      }
      
      await refetch();
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };

  const handleSaveComment = async (comment: SaveCommentProps) => {
    try {
      const response = await createComment({ 
        variables: { 
          phonecallId: id, 
          ...comment 
        } 
      });
      
      // Update local state with the saved comment
      setComments(prevComments => 
        prevComments.map(c => 
          c.id === comment.id ? response.data.commentCreate.comment : c
        )
      );
      
      setSelectedComment(response.data.commentCreate.comment);
      await refetch();
    } catch (error) {
      console.error('Error saving comment:', error);
      toast.error('Failed to save comment');
    }
  };

  const handleSaveReply = async (parentCommentId: string, text: string) => {
    await createReply({ variables: { phonecallId: id, parentCommentId, text } });
    await refetch();
  };

  // Move mainContentStyles here
  const mainContentStyles = {
    marginRight: '400px',
    width: '100%',
    transition: 'margin-right 0.3s ease-in-out',
  };

  // Add a ref to store the play function
  const [play, setPlay] = useState<(() => void) | null>(null);
  const [playSegment, setPlaySegment] = useState<((start: number, end: number) => void) | null>(null);

  // Create stable callback functions using useCallback
  const handleSeekTo = useCallback((time: number) => {
    seekTo?.(time);
    if (!isPlaying && play) {
      play();
    }
  }, [seekTo, isPlaying, play]);

  const handlePlaySegment = useCallback((start: number, end: number) => {
    console.log('Handling play segment', start, end);
    if (playSegment) {
      playSegment(start, end);
    }
  }, [playSegment]);

  // Move the request handlers to the top level
  const handleSeekRequest = useCallback((fn: (time: number) => void) => {
    setSeekTo(() => fn);
  }, []);

  const handlePlayRequest = useCallback((fn: () => void) => {
    setPlay(() => fn);
  }, []);

  const handlePlaySegmentRequest = useCallback((fn: (start: number, end: number) => void) => {
    setPlaySegment(() => fn);
  }, []);

  // Add query for review statuses
  const { data: reviewStatusesData } = useQuery(GET_PHONECALL_REVIEW_STATUSES);
  
  // Add mutation for updating review status
  const [updateReviewStatus, { loading: updatingStatus }] = useMutation(UPDATE_PHONECALL_REVIEW_STATUS);
  
  const handleUpdateReviewStatus = async (statusId: string) => {
    try {
      await updateReviewStatus({
        variables: {
          input: {
            phonecallId: id,
            statusId: statusId
          }
        }
      });
      sendAmplitudeData("phonecall-review-status-update", { id, statusId });
      await refetch();
    } catch (error) {
      console.error("Error updating review status:", error);
      toast.error("Failed to update review status");
    }
  };

  // Replace loading state with updatingItemId
  const [updatingItemId, setUpdatingItemId] = useState<string | null>(null);

  // Update handleApplyChecklist to use the mutation
  const handleApplyChecklist = async (checklistId: string) => {
    try {
      const response = await createChecklist({
        variables: {
          phonecallId: id,
          checklistId: checklistId
        }
      });
      
      setActiveChecklistId(response.data.phonecallChecklistCreate.phonecallChecklist.id);
      setShowChecklistPanel(true);
      setShowChecklistModal(false);
      
      // Close comment panel if open
      setSelectedComment(null);
      setShowCommentBox(false);
      
      toast.success("Checklist added successfully");
      
      // Refetch call data to get the newly added checklist
      await refetch();
    } catch (error) {
      console.error("Error creating checklist:", error);
      toast.error("Failed to add checklist");
    }
  };
  
  // Update handleUpdateChecklistItem to track which item is being updated
  const handleUpdateChecklistItem = async (itemId: string, updates: { checked: boolean; notes?: string }) => {
    try {
      setUpdatingItemId(itemId); // Set the ID of the item being updated
      
      await updateChecklistItem({
        variables: {
          checklistItemId: itemId,
          isChecked: updates.checked,
          notes: updates.notes
        }
      });
      
      // Refetch call data to get updated checklist items
      await refetch();
    } catch (error) {
      console.error("Error updating checklist item:", error);
      toast.error("Failed to update checklist item");
    } finally {
      setUpdatingItemId(null); // Clear the updating item ID when done
    }
  };
  
  // Get the active checklist from the call data
  const activeChecklist = data?.phonecall.checklists?.find(c => c.id === activeChecklistId) || null;
  
  // Compute checklist completion stats (for the display card)
  const getChecklistStats = (checklist) => {
    if (!checklist || !checklist.phonecallChecklistItems) return { completed: 0, total: 0, progress: 0 };
    
    const completed = checklist.phonecallChecklistItems.filter(item => item.isChecked).length;
    const total = checklist.phonecallChecklistItems.length;
    const progress = total > 0 ? Math.round((completed / total) * 100) : 0;
    
    return { completed, total, progress };
  };

  const [showChecklistModal, setShowChecklistModal] = useState(false);

  return (
    <div>
      <div className="d-flex">
        <div className="py-4 px-3 flex-grow-1" style={mainContentStyles}>
          <Row className="mb-3">
            <Col xs="12">
              <Breadcrumb>
                <NavLink to="/dashboard" className="breadcrumb-item">
                  Dashboard
                </NavLink>
                <Breadcrumb.Item active>Call</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          {!loading && (
            <div>
              <Row className="mb-3">
                <Col lg="6">
                  <dl className="row">
                    <dt className="col-sm-3">Caller</dt>
                    <dd className="col-sm-9">
                      <PhoneDisplay 
                        phoneNumber={data?.phonecall.callingPartyNumber || ""} 
                        agentId={data?.phonecall.callingAgent}
                      />
                    </dd>
                    <dt className="col-sm-3">Dialled</dt>
                    <dd className="col-sm-9">
                      <PhoneDisplay 
                        phoneNumber={data?.phonecall.dialledPartyNumber || ""} 
                        agentId={data?.phonecall.dialledAgent}
                      />
                    </dd>
                    <dt className="col-sm-3">When</dt>
                    <dd className="col-sm-9">
                      <div className="d-flex">
                        {data ? (
                          <DateTime value={data?.phonecall.eventDateTime} />
                        ) : (
                          "-"
                        )}
                      </div>
                    </dd>
                    <dt className="col-sm-3">Status</dt>
                    <dd className="col-sm-9">
                      <div className="d-flex">
                        <Form.Select 
                          size="sm"
                          className="w-auto"
                          value={data?.phonecall.reviewStatusOptionId || ''}
                          onChange={(e) => handleUpdateReviewStatus(e.target.value)}
                          disabled={updatingStatus}
                        >
                          <option value="">Select status</option>
                          {reviewStatusesData?.phonecallReviewStatuses.nodes.map((status) => (
                            <option key={status.id} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Form.Select>
                        {updatingStatus && (
                          <div className="ms-2 d-flex align-items-center">
                            <i className="bi-spinner bi-spin text-secondary"></i>
                          </div>
                        )}
                      </div>
                    </dd>
                    <dt className="col-sm-3">Bookmark</dt>
                    <dd className="col-sm-9">
                      <div className="d-flex">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label=""
                          checked={isCheckboxChecked}
                          onChange={(e) =>
                            handleSetBookmarked(data?.phonecall.id, e.target.checked)
                          }
                        />
                      </div>
                    </dd>
                    <dt className="col-sm-3">Tags</dt>
                    <dd className="col-sm-9">
                      <div className="d-flex gap-2 position-relative mb-2">
                        <div className="position-relative w-100">
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Add new tag"
                            value={newTag}
                            disabled={isAddingTag}
                            onChange={(e) => {
                              setNewTag(e.target.value);
                              setShowTagSuggestions(true);
                            }}
                            onFocus={() => setShowTagSuggestions(true)}
                            onBlur={() => {
                              setTimeout(() => setShowTagSuggestions(false), 200);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && newTag.trim()) {
                                e.preventDefault();
                                handleAddTag();
                              }
                            }}
                          />
                          {isAddingTag && (
                            <div 
                              className="position-absolute top-50 end-0 translate-middle-y me-2"
                              style={{ pointerEvents: 'none' }}
                            >
                              <i className="bi-spinner bi-spin text-secondary"></i>
                            </div>
                          )}
                        </div>
                        
                        {showTagSuggestions && filteredTags.length > 0 && (
                          <div 
                            className="position-absolute bg-white border rounded shadow-sm w-100"
                            style={{ 
                              top: '100%', 
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto'
                            }}
                          >
                            {filteredTags.map(tag => (
                              <Tag
                                key={tag.id}
                                name={tag.name}
                                color={tag.color}
                                showDot={true}
                                onClick={() => handleAddTag(tag.id, tag.name)}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        {data?.phonecall.tags?.map((tag) => (
                          <AppliedTag
                            key={tag.id}
                            id={tag.id}
                            name={tag.name}
                            createdByUserId={tag.createdByUserId}
                            color={tag.color}
                            isApproved={tag.isApproved}
                            onRemove={handleRemoveTag}
                            onReview={handleReviewTag}
                          />
                        ))}
                      </div>
                    </dd>
                    <dt className="col-sm-3">Checklists</dt>
                    <dd className="col-sm-9">
                      <div className="d-flex flex-column w-100">
                        {/* Add Checklist Button - only show if no checklists exist */}
                        {(!data?.phonecall.checklists || data.phonecall.checklists.length === 0) && (
                          <div className="mb-2">
                            <button 
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => setShowChecklistModal(true)}
                              disabled={creatingChecklist}
                            >
                              {creatingChecklist ? (
                                <>
                                  <i className="bi-spinner bi-spin me-1"></i>
                                  Adding...
                                </>
                              ) : (
                                <>
                                  <i className="bi-check2-square me-1"></i>
                                  Add Checklist
                                </>
                              )}
                            </button>
                          </div>
                        )}
                        
                        {/* List of active checklists */}
                        {data?.phonecall.checklists && data.phonecall.checklists.length > 0 ? (
                          <div>
                            {data.phonecall.checklists.map(checklist => {
                              const { completed, total, progress } = getChecklistStats(checklist);
                              return (
                                <div 
                                  className="card mb-2" 
                                  key={checklist.id}
                                  onClick={() => {
                                    setActiveChecklistId(checklist.id);
                                    setShowChecklistPanel(true);
                                    setSelectedComment(null);
                                    setShowCommentBox(false);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="card-body p-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        <strong>{checklist.name}</strong>
                                        <div className="small mt-1">
                                          {completed} of {total} items completed
                                        </div>
                                        <div className="progress mt-1" style={{ height: '5px' }}>
                                          <div 
                                            className="progress-bar" 
                                            role="progressbar" 
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow={progress} 
                                            aria-valuemin={0} 
                                            aria-valuemax={100}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="text-muted small">No checklists applied to this call.</div>
                        )}
                      </div>
                    </dd>
                  </dl>
                </Col>
                <Col lg="6" className={"text-end"}></Col>
                <br />
                <Col xs="12" className="mb-4" style={{ minHeight: 60 }}>
                  <AudioPlayer
                    phonecallId={data?.phonecall.id}
                    onTimeUpdate={(time) => setCurrentTime(time)}
                    onPlayingStateChange={(playing) => setIsPlaying(playing)}
                    onSeekRequest={handleSeekRequest}
                    onPlayRequest={handlePlayRequest}
                    onPlaySegmentRequest={handlePlaySegmentRequest}
                  />
                </Col>
                <CallContent
                  data={data}
                  handleMouseUpOnTranscription={handleMouseUpOnTranscription}
                  currentTime={currentTime}
                  isPlaying={isPlaying}
                  onSeekTime={handleSeekTo}
                  comments={comments}
                  onCommentSelect={setSelectedComment}
                  //onShowCommentBox={setShowCommentBox}
                />
              </Row>
            </div>
          )}
        </div>
        
        {/* Update Checklist Modal to use server data */}
        <ChecklistModal
          show={showChecklistModal}
          onHide={() => setShowChecklistModal(false)}
          templates={checklistTemplatesData?.checklists || []}
          onApply={handleApplyChecklist}
          loading={loadingTemplates}
        />
        
        {/* Comment Side Panel */}
        {(selectedComment || showCommentBox) && (
          <div style={sidebarStyles}>
            <CommentSidePanel
              comments={comments}
              onSaveComment={handleSaveComment}
              onDeleteComment={handleDeleteComment}
              onSaveReply={handleSaveReply}
              selectedComment={selectedComment}
              words={data?.phonecall.transcript?.words}
              onSeekTime={handleSeekTo}
              onClose={() => {
                setSelectedComment(null);
                setShowCommentBox(false);
                setComments(prevComments => 
                  prevComments.filter(c => !c.id.startsWith('temp-'))
                );
              }}
              onPlaySegment={handlePlaySegment}
            />
          </div>
        )}
        
        {/* Update Checklist Side Panel to use server data */}
        {showChecklistPanel && activeChecklist && (
          <div style={sidebarStyles}>
            <ChecklistSidePanel
              checklist={activeChecklist}
              onUpdateItem={handleUpdateChecklistItem}
              onClose={() => setShowChecklistPanel(false)}
              updatingItemId={updatingItemId}
            />
          </div>
        )}
      </div>
    </div>
  );
};
