import { useEffect, RefObject } from 'react';

export const useAutoExpandingTextarea = (textareaRef: RefObject<HTMLTextAreaElement>) => {
  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const adjustHeight = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    textarea.addEventListener('input', adjustHeight);
    return () => textarea.removeEventListener('input', adjustHeight);
  }, [textareaRef]);
}; 