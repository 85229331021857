import React from 'react';
import { Comment } from '../types/transcript';

interface SpeakerLineProps {
  speaker: string;
  words: Array<{
    globalIndex: number;
    text: string;
    speaker: string;
    start: number;
    end: number;
  }>;
  currentTime: number;
  onSeekTime?: ((time: number) => void) | null;
  comments: Comment[];
  //selectedComment: Comment | null;
  onSelectComment: (comment: Comment | null) => void;
}

const SpeakerLine: React.FC<SpeakerLineProps> = ({
    speaker,
    words,
    currentTime,
    onSeekTime,
    comments,
    //selectedComment,
    onSelectComment
}) => {

  const colorDictionary: Record<number, string> = {
    1: '#E5E5FF', // Light Blue
    2: '#FFFFE5', // Light Yellow
    3: '#FFE5FF', // Light Magenta
    4: '#FFE5E5', // Light Red
    5: '#E5FFE5', // Light Green
  };

  function formatTime(seconds: number): string {
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  // Helper function to check if a word is part of a comment's quoted text
  const isWordInComment = (globalIndex: number, comments: Comment[]): Comment | null => {
    for (const comment of comments) {
      if (comment.quotedText) {
        if (globalIndex >= comment.quotedTextStartWord && globalIndex <= comment.quotedTextEndWord) {
          return comment;
        }
      }
    }
    return null;
  };

  speaker = speaker + 1;
  const backgroundColor = colorDictionary[speaker] || '#F0F0F0';
  const lineStartTime = words.length > 0 ? words[0].start : 0;
  const time = formatTime(lineStartTime);

  return (
    <div className="row mb-3">
      <div className="col-auto">
        <div
          className="d-flex align-items-center flex-shrink-0 justify-content-center border rounded-circle"
          style={{
            width: '45px',
            height: '45px',
            backgroundColor: backgroundColor
          }}>{speaker}</div>
      </div>
      <div className="col">
        <div>
          <span
            className="text-muted small"
            onClick={() => onSeekTime?.(lineStartTime)}
            role="button">
            {time ?? ""}
          </span>
        </div>
        {words.map((word, index) => {
          const matchingComment = isWordInComment(word.globalIndex, comments);
          const isCurrentWord = currentTime >= word.start-0.05 && currentTime <= word.end+0.05;
          return (
            <span
              key={word.globalIndex}
              style={{
                backgroundColor: matchingComment ? 'yellow' : 'transparent',
                cursor: matchingComment ? 'pointer' : 'inherit',
                textDecoration: isCurrentWord ? 'underline' : 'none'
              }}
              onClick={() => matchingComment && onSelectComment(matchingComment)}
            >
              {word.text}{' '}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default SpeakerLine;
