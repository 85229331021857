import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface ChecklistItemType {
  id: string;
  //checklistItemId: string;
  description: string;
  displayOrder: number;
  isChecked: boolean;
  notes?: string;
}

interface ChecklistType {
  id: string;
  checklistId: string;
  name: string;
  status: string;
  phonecallChecklistItems: ChecklistItemType[];
}

interface ChecklistSidePanelProps {
  checklist: ChecklistType;
  onUpdateItem: (itemId: string, updates: { checked: boolean; notes?: string }) => void;
  onClose: () => void;
  updatingItemId?: string;
}

export const ChecklistSidePanel: React.FC<ChecklistSidePanelProps> = ({
  checklist,
  onUpdateItem,
  onClose,
  updatingItemId,
}) => {
  // Get count of checked items
  const checkedCount = checklist.phonecallChecklistItems?.filter(item => item.isChecked).length || 0;
  const totalCount = checklist.phonecallChecklistItems?.length || 0;
  const progress = totalCount > 0 ? Math.round((checkedCount / totalCount) * 100) : 0;
  
  // Keep track of which items have expanded notes sections
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  
  const toggleItemExpanded = (itemId: string) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };
  
  return (
    <div className="d-flex flex-column h-100">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">{checklist.name || 'Checklist'}</h4>
        <Button variant="link" className="p-0 text-secondary" onClick={onClose}>
          <i className="bi-x-lg"></i>
        </Button>
      </div>
      
      {/* Status Section */}
      <div className="mb-3">
        <div className="alert alert-info">
          <i className="bi-info-circle me-2"></i>
          Check off items as you review the call for compliance
        </div>
      </div>
      
      {/* Progress Bar */}
      <div className="mb-3">
        <div className="d-flex justify-content-between mb-1">
          <span className="small">Progress</span>
          <span className="small">{checkedCount} of {totalCount} items</span>
        </div>
        <div className="progress">
          <div 
            className="progress-bar" 
            role="progressbar" 
            style={{ width: `${progress}%` }}
            aria-valuenow={progress} 
            aria-valuemin={0} 
            aria-valuemax={100}
          ></div>
        </div>
      </div>
      
      {/* Checklist Items */}
      <div className="flex-grow-1 overflow-auto">
        {checklist.phonecallChecklistItems?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((item) => (
          <ChecklistItemComponent 
            key={item.id}
            item={item}
            isExpanded={!!expandedItems[item.id]}
            onToggleExpanded={() => toggleItemExpanded(item.id)}
            onUpdateItem={onUpdateItem}
            isUpdating={item.id === updatingItemId}
          />
        ))}
      </div>
    </div>
  );
};

interface ChecklistItemComponentProps {
  item: ChecklistItemType;
  isExpanded: boolean;
  onToggleExpanded: () => void;
  onUpdateItem: (itemId: string, updates: { checked: boolean; notes?: string }) => void;
  isUpdating?: boolean;
}

const ChecklistItemComponent: React.FC<ChecklistItemComponentProps> = ({
  item,
  isExpanded,
  onToggleExpanded,
  onUpdateItem,
  isUpdating = false,
}) => {
  const [notes, setNotes] = useState(item.notes || '');
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  
  // Reset notes state when item changes
  useEffect(() => {
    setNotes(item.notes || '');
    setHasUnsavedChanges(false);
  }, [item.notes]);

  const handleNotesChange = (value: string) => {
    setNotes(value);
    setHasUnsavedChanges(value !== item.notes);
  };
  
  const handleSaveNotes = async () => {
    if (notes === item.notes) return;
    
    setIsSavingNotes(true);
    try {
      await onUpdateItem(item.id, { checked: item.isChecked, notes });  
    } finally {
      setIsSavingNotes(false);
    }
  };

  const handleCheckboxChange = (checked: boolean) => {
    onUpdateItem(item.id, { checked, notes });
  };
  
  return (
    <div className={`card mb-2 ${item.isChecked ? 'bg-light' : ''} position-relative`}>
      {isUpdating && (
        <div className="position-absolute" style={{ top: '5px', right: '5px', zIndex: 1 }}>
          <Spinner animation="border" size="sm" variant="primary" style={{ width: '1rem', height: '1rem' }} />
        </div>
      )}
      
      <div className="card-body">
        <div className="d-flex align-items-start">
          <Form.Check
            type="checkbox"
            id={`item-${item.id}`}
            className="me-2 mt-1"
            checked={item.isChecked}
            onChange={(e) => handleCheckboxChange(e.target.checked)}
            disabled={isUpdating}
          />
          
          <div className="flex-grow-1">
            <div>
              {item.description}
            </div>
            
            {/* Notes Section */}
            <div className="mt-2">
              <Button 
                variant="link" 
                size="sm" 
                className="p-0 text-decoration-none"
                onClick={onToggleExpanded}
              >
                {isExpanded ? (
                  <>
                    <i className="bi-chevron-up me-1"></i>
                    Hide Notes
                  </>
                ) : (
                  <>
                    <i className="bi-chevron-down me-1"></i>
                    {notes ? 'Edit' : 'Add'} Notes
                  </>
                )}
              </Button>
              
              {isExpanded && (
                <div className="mt-2">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Add notes or evidence that this item was satisfied..."
                      value={notes}
                      onChange={(e) => handleNotesChange(e.target.value)}
                      disabled={isUpdating || isSavingNotes}
                    />
                    
                    <div className="d-flex justify-content-end align-items-center mt-1">
                      {hasUnsavedChanges && (
                        <>
                          <small className="text-muted me-2">Unsaved changes</small>
                          
                          {isSavingNotes ? (
                            <Spinner animation="border" size="sm" variant="secondary" style={{ width: '1rem', height: '1rem' }} />
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`save-tooltip-${item.id}`}>Save notes</Tooltip>}
                            >
                              <Button 
                                variant="outline-primary" 
                                size="sm" 
                                className="py-0 px-2"
                                onClick={handleSaveNotes}
                                disabled={isUpdating}
                              >
                                <i className="bi-save"></i>
                              </Button>
                            </OverlayTrigger>
                          )}
                        </>
                      )}
                    </div>
                  </Form.Group>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 