import React from 'react';
import { Data, Comment } from '../types/transcript';
import SpeakerLine from './SpeakerLine';

interface TranscriptTextProps {
    data: Data;
    currentTime: number;
    onSeekTime?: ((time: number) => void) | null;
    comments: Comment[];
    //selectedComment: Comment | null;
    onSelectComment: (comment: Comment | null) => void;
    onContextMenu: (event: React.MouseEvent) => void;
}

export const TranscriptText: React.FC<TranscriptTextProps> = ({
    data,
    currentTime,
    onSeekTime,
    comments,
    //selectedComment,
    onSelectComment,
    onContextMenu
}) => {
    const speakerSegments = [];
    let currentSegment = null;

    // Iterate through words to build segments
    for (let i = 0; i < data.words.length; i++) {
        const word = { ...data.words[i], globalIndex: i };

        if (!currentSegment || (currentSegment.speaker !== word.speaker && word.speaker !== -1)) {
            // Start a new segment
            currentSegment = {
                speaker: word.speaker,
                words: [word]
            };
            speakerSegments.push(currentSegment);
        } else {
            // Add to existing segment
            currentSegment.words.push(word);
        }
    }

    return (
        <div onContextMenu={onContextMenu}>
            {speakerSegments.map((segment, index) => (
                <SpeakerLine
                    key={index}
                    speaker={segment.speaker}
                    words={segment.words}
                    currentTime={currentTime}
                    onSeekTime={onSeekTime}
                    comments={comments}
                    //selectedComment={selectedComment}
                    onSelectComment={onSelectComment}
                />
            ))}
        </div>
    );
}; 