import React, { createContext, useState, useEffect, useContext } from 'react';
import { GET_USERS } from '../queries/Users';
import { useQuery } from '@apollo/client';

// Define User type based on the query
export interface User {
  id: string;
  callerId: string;
  email: string;
  lastLoginDateTime: string;
  role: string;
  firstName: string;
  lastName: string;
  color: string;
}

interface AgentContextType {
  users: User[];
  loading: boolean;
  getUserColor: (userId: string) => string;
}

// Array of pastel colors that work well with white text
// These are medium-brightness pastels with good contrast
const USER_COLORS = [
  '#5D8CAE', // Pastel blue
  '#8FB26B', // Pastel green
  '#9D7AC8', // Pastel purple
  '#D7804E', // Pastel orange
  '#4E94B7', // Pastel teal
  '#7F8E4F', // Pastel olive
  '#AD6EC0', // Pastel violet
  '#628BB5', // Pastel steel blue
  '#BD7D45'  // Pastel amber
];

// Generate a deterministic color based on a string (userId)
const generateUserColor = (userId: string): string => {
  // Calculate a simple hash of the userId
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
    hash = userId.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Use the hash to pick a color from our palette
  const colorIndex = Math.abs(hash) % USER_COLORS.length;
  return USER_COLORS[colorIndex];
};

const AgentContext = createContext<AgentContextType>({
  users: [],
  loading: true,
  getUserColor: () => '#5D8CAE' // Default color
});

export const AgentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, loading } = useQuery(GET_USERS);
  const [users, setUsers] = useState<User[]>([]);
  
  // Update users when data changes
  useEffect(() => {
    if (data?.users) {
      // Add color to each user
      const usersWithColors = data.users.map((user: User) => ({
        ...user,
        color: generateUserColor(user.id)
      }));
      setUsers(usersWithColors);
    }
  }, [data]);

  // Function to get a user's color by ID
  const getUserColor = (userId: string): string => {
    const user = users.find(u => u.id === userId);
    if (user) return user.color;
    return generateUserColor(userId); // Generate color on-the-fly if user not found
  };

  return (
    <AgentContext.Provider value={{ users, loading, getUserColor }}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgents = () => useContext(AgentContext); 