import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Transcript } from "./Transcript"
import React from "react";
import { Comment } from "../types/transcript";

interface CallContentProps {
  data: any;
  handleMouseUpOnTranscription: (event: any) => void;
  currentTime: number;
  isPlaying: boolean;
  onSeekTime?: ((time: number) => void) | null;
  onCommentSelect: (comment: Comment | null) => void;
  comments: Comment[];
}

const CallContent: React.FC<CallContentProps> = ({
    data,
    currentTime,
    isPlaying,
    onSeekTime,
    onCommentSelect,
    comments,
}) => {
  const renderKeyFacts = () => {
    if (!data?.phonecall.keyFacts) {
      return "Please Transcribe the call to enable Key Fact analysis";
    }

    // Split the key facts by newline and create bullet points
    const facts = data.phonecall.keyFacts.split('\n').filter(fact => fact.trim());
    return (
      <ul>
        {facts.map((fact, index) => (
          <li key={index}>{fact.trim()}</li>
        ))}
      </ul>
    );
  };

  return (
    <Col xs="12" lg="12" className="h-full">
      <Tabs
        defaultActiveKey="keyfacts"
        id="justify-tab-example"
        className="mb-3 h-full flex flex-col"
        justify
      >
        <Tab eventKey="keyfacts" title="Key Facts" key="key-facts">
          {renderKeyFacts()}
        </Tab>
        <Tab
          eventKey="transcription"
          title="Transcript"
          key="transcript"
          className="flex-1"
        >
          {data.phonecall.transcript ? (
            <div className="h-[calc(100vh-300px)] flex">
              <div className="flex-1">
                <Transcript
                  phonecallId={data.phonecall.id}
                  data={data.phonecall.transcript}
                  currentTime={currentTime}
                  onSeekTime={onSeekTime}
                  initialComments={data?.phonecall.comments || []}
                  onCommentSelect={onCommentSelect}
                />
              </div>
            </div>
          ) : (
            "Please Transcribe the call to enable Transcription"
          )}
        </Tab>
        <Tab eventKey="sentiment" title="Sentiment" key="title">
          <div style={{ whiteSpace: "pre-wrap" }}>
            {data?.phonecall.sentiment ??
              "Please Transcribe the call to enable Sentiment analysis"}
          </div>
        </Tab>
      </Tabs>
    </Col>
  );
};

export default CallContent;
