import React, { useState } from 'react';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { TranscriptText } from './TranscriptText';
import { Comment, Data } from '../types/transcript';

const MENU_ID = 'transcript-context-menu';

interface TranscriptProps {
    data: Data;
    currentTime: number;
    onSeekTime?: ((time: number) => void) | null;
    phonecallId: string;
    initialComments?: Comment[];
    onCommentSelect: (comment: Comment | null) => void;
}

export const Transcript: React.FC<TranscriptProps> = ({
    data,
    currentTime = 0,
    onSeekTime,
    phonecallId,
    initialComments = [],
    onCommentSelect,
}) => {
    const { show } = useContextMenu({
        id: MENU_ID,
    });
    const [selectedText, setSelectedText] = useState<{
        text: string;
        range: Range | null;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        
        const selection = window.getSelection();
        if (selection && selection.toString().trim()) {
            setSelectedText({
                text: selection.toString(),
                range: selection.getRangeAt(0).cloneRange()
            });
            show({ event });
        }
    };

    const handleAddCommentContextAction = ({ event }: { event: any }) => {
        if (!selectedText) return;

        const selectedTextContent = selectedText.text;
        if (!selectedTextContent) return;

        // Find the word indices that contain the selected text
        const allText = data.words.map(w => w.text).join(' ');
        const startCharIndex = allText.indexOf(selectedTextContent);
        if (startCharIndex === -1) return;

        // Count words before selection to get starting word index
        const textBeforeSelection = allText.substring(0, startCharIndex);
        const startWordIndex = textBeforeSelection.split(' ').length - 1;
        const endWordIndex = startWordIndex + selectedTextContent.split(' ').length - 1;
        
        const newComment: Comment = {
            id: `temp-${Date.now()}`,
            quotedText: selectedTextContent,
            text: '',
            createdDateTime: new Date().toISOString(),
            quotedTextStartWord: startWordIndex,
            quotedTextEndWord: endWordIndex,
            startWordIndex: startWordIndex,
            endWordIndex: endWordIndex,
            comments: []
        };

        onCommentSelect(newComment);
        //onShowCommentBox(true);
        setSelectedText(null);
    };

    return (
        <div className="h-full flex">
            <div className="flex-1">
                <TranscriptText
                    data={data}
                    currentTime={currentTime}
                    onSeekTime={onSeekTime}
                    comments={initialComments}
                    //selectedComment={selectedComment}
                    onSelectComment={onCommentSelect}
                    onContextMenu={handleContextMenu}
                />

                <Menu id={MENU_ID}>
                    <Item onClick={handleAddCommentContextAction}>
                        Add Comment
                    </Item>
                </Menu>
            </div>
        </div>
    );
};