import gql from "graphql-tag";

export const GET_USERS = gql`
  query getUsers {
    users {
      id
      callerId
      email
      lastLoginDateTime
      role
      firstName
      lastName
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;