import React from 'react';
import { useAgents } from '../../context/AgentContext';

interface PhoneDisplayProps {
  phoneNumber: string;
  agentId?: string;
}

const PhoneDisplay: React.FC<PhoneDisplayProps> = ({ 
  phoneNumber, 
  agentId, 
}) => {
  const { users, loading } = useAgents();
  
  // Determine what to display
  let displayValue = phoneNumber;
  
  if (!loading && users.length > 0) {
    // First try to find a user by agentId
    if (agentId) {
      const userById = users.find(user => user.callerId === agentId);
      if (userById) {
        const names = [userById.firstName, userById.lastName].filter(Boolean).join(" ");
        if (names) displayValue = names;
      }
    }
    
    // If that fails, try to find a user by phone number
    if (displayValue === phoneNumber && phoneNumber) {
      const userByPhone = users.find(user => user.callerId === phoneNumber);
      if (userByPhone) {
        const names = [userByPhone.firstName, userByPhone.lastName].filter(Boolean).join(" ");
        if (names) displayValue = names;
      }
    }
    // Otherwise, fall back to the raw phone number (already set as default)
  }
  
  return (
    <span>
      {displayValue}
    </span>
  );
};

export default PhoneDisplay; 