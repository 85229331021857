import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

interface ChecklistTemplate {
  id: string;
  name: string;
  description: string;
  checklistItems: Array<{
    id: string;
    description: string;
  }>;
}

interface ChecklistModalProps {
  show: boolean;
  onHide: () => void;
  templates: ChecklistTemplate[];
  onApply: (templateId: string) => void;
  loading?: boolean;
}

export const ChecklistModal: React.FC<ChecklistModalProps> = ({
  show,
  onHide,
  templates,
  onApply,
  loading = false,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');

  const handleApply = () => {
    if (selectedTemplateId) {
      onApply(selectedTemplateId);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Checklist Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted">
          Select a checklist template to apply to this call.
          Checklists help evaluate compliance with regulatory requirements.
        </p>
        
        {loading ? (
          <div className="text-center p-4">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="mt-2">Loading checklist templates...</p>
          </div>
        ) : templates.length === 0 ? (
          <div className="alert alert-info">
            No checklist templates available. Please contact your administrator.
          </div>
        ) : (
          <Form>
            {templates.map((template) => (
              <Card 
                key={template.id} 
                className={`mb-3 ${selectedTemplateId === template.id ? 'border-primary' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedTemplateId(template.id)}
              >
                <Card.Body>
                  <div className="d-flex align-items-start">
                    <Form.Check
                      type="radio"
                      id={`template-${template.id}`}
                      name="template"
                      className="me-2 mt-1"
                      checked={selectedTemplateId === template.id}
                      onChange={() => setSelectedTemplateId(template.id)}
                    />
                    <div>
                      <Card.Title>{template.name}</Card.Title>
                      <Card.Text className="text-muted">{template.description}</Card.Text>
                      <div className="small text-muted">
                        {template.checklistItems?.length || 0} items in this checklist
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleApply}
          disabled={!selectedTemplateId || loading}
        >
          Apply Checklist
        </Button>
      </Modal.Footer>
    </Modal>
  );
}; 